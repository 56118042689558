import dayjs, { Dayjs } from 'dayjs';
import weekday from 'dayjs/plugin/weekday'
dayjs.extend(weekday)

export enum Weekday {
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY'
}

export type CustomerDeliveryDaysType = Record<Weekday, boolean>;

const WeekdayToDayNumberMap: Record<Weekday, number> = {
  [Weekday.MONDAY]: 0,
  [Weekday.TUESDAY]: 1,
  [Weekday.WEDNESDAY]: 2,
  [Weekday.THURSDAY]: 3,
  [Weekday.FRIDAY]: 4,
  [Weekday.SATURDAY]: 5,
  [Weekday.SUNDAY]: 6,
};

const getDeliveryWeekDaysEntries = (customerDeliveryDays: CustomerDeliveryDaysType) => {
  return Object.entries(customerDeliveryDays)
    .filter(([, canDeliver]) => canDeliver)
}

export const DatePickerValueCalculator = (
  customerDeliveryDays: CustomerDeliveryDaysType,
  addressDeliveryDays: number,
  minDeliveryDate: Dayjs
): any => {
  const validAddressDeliveryDays = addressDeliveryDays > 1 ? addressDeliveryDays -1 : 0
  const validDeliveryWeekdays= getDeliveryWeekDaysEntries(customerDeliveryDays).map(([day]) => {
    const dayOfWeek = dayjs().weekday(WeekdayToDayNumberMap[day as Weekday] + validAddressDeliveryDays).weekday()
    return dayOfWeek >= 5 ? 0 : dayOfWeek
  })

  const earliestSelectableDate = minDeliveryDate.add(addressDeliveryDays, 'days');

  return (current: Dayjs) => {
    if (!current) return true;
    if (current.isBefore(earliestSelectableDate, 'day')) return true;
    return !validDeliveryWeekdays.includes(current.weekday());
  };
};

export const getDeliveryDay = (
  selectedDate: Dayjs,
  addressDeliveryDays: number = 0,
  customerDeliveryDays: CustomerDeliveryDaysType
): any => {
  const validDeliveryWeekdays = getDeliveryWeekDaysEntries(customerDeliveryDays)
    .map(([day]) => dayjs().weekday(WeekdayToDayNumberMap[day as Weekday]).weekday())
  let deliveryDate = selectedDate.add(-addressDeliveryDays, 'days');

  while (!validDeliveryWeekdays.includes(deliveryDate.weekday())) {
    deliveryDate = deliveryDate.add(-1, 'days')
  }

  return deliveryDate;
}