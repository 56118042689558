import { Button, DatePicker, Form, FormInstance, FormListFieldData, InputNumber, Select, Table, Tooltip } from 'antd';
import React, { useCallback, useMemo } from 'react';
import { ColumnType } from 'antd/es/table';
import { CopyOutlined, DeleteOutlined } from '@ant-design/icons';
import { PurchaseOrderFormInstance } from './OrderEdit.tsx';
import FlexBox from '../../common/FlexBox.tsx';
import { FormattedMessage, useIntl } from 'react-intl';
import { translate, translateUnit } from '../../../translations/TranslationUtils.ts';
import dayjs from 'dayjs';
import { ProductTreeSelectGroupOption } from '../../../persistence/model/Product.ts';
import './OrderEditItemsForm.css';
import { AddOrderProductRequest } from '../../../persistence/model/Order.ts';
import { isNumber } from 'lodash';
import ProductRenderer from '../../product/ProductRenderer.tsx';
import { StringBasedKeyVal } from '../../../persistence/model/Common.ts';
import { formatRequestDate } from '../../../util/DateUtil.ts';
import { CustomerDeliveryDaysType, DatePickerValueCalculator } from './OrderEditItemsFormHelper.ts';

type PurchaseOrderCreationItemsFormProps = {
  customerId: number;
  isStandard: boolean;
  items: FormListFieldData[];
  loading?: boolean;
  form: FormInstance<PurchaseOrderFormInstance>;
  addRow: (request: AddOrderProductRequest) => Promise<any>;
  deleteRow: (rowId: number) => Promise<any>;
  productsMap: Map<number, ProductTreeSelectGroupOption>;
  onRowChange: (index: number) => void;
  addressDeliveryDays?: number;
  customerDeliveryDays?: CustomerDeliveryDaysType;
  productGroupDeliveryTermsMap:  StringBasedKeyVal<number>;
};

const OrderEditItemsForm = ({
  isStandard,
  items,
  loading,
  form,
  productsMap,
  addRow,
  deleteRow,
  onRowChange,
  addressDeliveryDays,
  customerDeliveryDays,
  productGroupDeliveryTermsMap,
}: PurchaseOrderCreationItemsFormProps): JSX.Element => {
  const intl = useIntl();

  const getProduct = useCallback(
    (index: any) => {
      const productId = form.getFieldValue(['items', index]).productId;
      return productsMap.get(productId);
    },
    [form, productsMap]
  );

  const columns = useMemo(
    () =>
      [
        {
          key: 'name',
          title: translate(intl, 'order.form.itemsTable.item'),
          dataIndex: 'name',
          render: (_, { name }) => {
            const product = getProduct(name);
            return (
              product && (
                <ProductRenderer
                  label={product.label}
                  productName={product.name}
                  productNumber={product.code}
                  externalItemId={product.externalItemId}
                  ean={product.ean}
                />
              )
            );
          },
        },
        {
          key: 'quantity',
          title: translate(intl, 'order.form.itemsTable.quantity'),
          width: 150,
          dataIndex: 'quantity',
          render: (_, { name }) => {
            const product = getProduct(name);
            const defaultUnit = product?.defaultUnit ? translateUnit(intl, product?.defaultUnit) : '';
            const availableQuantity = isNumber(product?.availableQuantity) ? product?.availableQuantity : 0;

            return (
              <Form.Item
                name={[name, 'quantity']}
                validateTrigger={['onChange', 'onBlur']}
                rules={[{ required: true, type: 'number', min: 1 }]}
                help={
                  isStandard ? (
                    <div style={{ position: 'absolute', color: '#21232280', fontSize: 12 }}>
                      <FormattedMessage id="order.form.itemsTable.available" values={{ count: availableQuantity, unit: defaultUnit }} />
                    </div>
                  ) : (
                    <span />
                  )
                }
                isListField={true}
              >
                <InputNumber
                  min={1}
                  style={{ width: '100%' }}
                  onBlur={() => onRowChange(name)}
                  placeholder={translate(intl, 'order.form.itemsTable.quantity')}
                />
              </Form.Item>
            );
          },
        },
        {
          key: 'unit',
          title: translate(intl, 'order.form.itemsTable.unit'),
          width: 150,
          render: (_, { name }) => {
            const product = getProduct(name);
            const units = product?.units?.map((unit) => ({ value: unit, label: translateUnit(intl, unit) })) || [];
            const defaultUnit = units?.length === 1 ? units[0].value : undefined;

            return (
              <Form.Item
                name={[name, 'unit']}
                validateTrigger={['onChange', 'onBlur']}
                rules={[{ required: true }]}
                initialValue={defaultUnit}
                isListField={true}
                help={<span />}
              >
                <Select<string> placeholder={translate(intl, 'order.form.itemsTable.unit')} options={units || []} onChange={() => onRowChange(name)} />
              </Form.Item>
            );
          },
        },
        {
          key: 'date',
          width: 150,
          title: translate(intl, 'order.form.itemsTable.deliveryDate'),
          render: (_, { name }) => {
            const product = getProduct(name);
            const productDeliveryDays = (product ? productGroupDeliveryTermsMap[product.productGroup] : 0) ?? 0;
            const minDate = dayjs().add(productDeliveryDays, 'days').startOf('day');

            const disabledDate = DatePickerValueCalculator(customerDeliveryDays, addressDeliveryDays, minDate);

            return (
              <Form.Item
                name={[name, 'date']}
                validateTrigger={['onChange', 'onBlur']}
                rules={[
                  { required: true },
                  {
                    validator: (_, value: dayjs.Dayjs) => {
                      if (!value || value.isBefore(minDate) || disabledDate(dayjs(value))) {
                        return Promise.reject('minDate');
                      }
                      return Promise.resolve(true);
                    },
                  },
                ]}
                help={<span />}
                isListField={true}
              >
                <DatePicker
                  format={translate(intl, 'config.dateFormat')}
                  allowClear={false}
                  onChange={() => onRowChange(name)}
                  disabledDate={disabledDate}
                  minDate={minDate}
                />
              </Form.Item>
            );
          },
        },
        {
          key: 'actions',
          width: '70px',
          render: (_, { name }) => (
            <FlexBox direction="horizontal" style={{ gap: '10px' }}>
              <Tooltip title={translate(intl, 'common.duplicate')}>
                <Button
                  icon={<CopyOutlined />}
                  size="large"
                  type="text"
                  disabled={form.getFieldValue(['items', name]).quantity === null || form.getFieldValue(['items', name]).date === undefined}
                  onClick={async () => {
                    const item = form.getFieldValue(['items', name]);
                    await addRow({
                      unit: item.unit,
                      date: formatRequestDate(item.date),
                      quantity: item.quantity,
                      productId: item.productId,
                    } as AddOrderProductRequest);
                  }}
                />
              </Tooltip>
              <Tooltip title={translate(intl, 'common.delete')}>
                <Button
                  icon={<DeleteOutlined />}
                  size="large"
                  type="text"
                  onClick={async () => {
                    const item = form.getFieldValue(['items', name]);
                    await deleteRow(item.id);
                  }}
                ></Button>
              </Tooltip>
            </FlexBox>
          ),
        },
      ] as ColumnType<FormListFieldData>[],
    [intl, getProduct, isStandard, onRowChange, productGroupDeliveryTermsMap, customerDeliveryDays, addressDeliveryDays, form, addRow, deleteRow]
  );

  return (
    <Table<FormListFieldData>
      size="small"
      loading={loading}
      className="purchase-order-items-table"
      pagination={false}
      columns={columns}
      dataSource={items}
    />
  );
};

export default OrderEditItemsForm;
