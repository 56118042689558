import { CustomerDeliveryDaysType } from '../../components/order/edit/OrderEditItemsFormHelper.ts';
import { Audit, PortalSortOrder } from './Common.ts';

export enum OrderStatus {
  DRAFT = 'DRAFT',
  ORDERED = 'ORDERED',
  UNCONFIRMED = 'UNCONFIRMED',
  REVIEW = 'REVIEW',
  CONFIRMED = 'CONFIRMED',
  DELIVERED = 'DELIVERED',
}

export enum OrderOrderBy {
  purchase_order_num = 'purchase_order_num',
  sales_order_num = 'sales_order_num',
  status = 'status',
  created_at = 'created_at',
}

export enum OrderCommentType {
  CREATION_COMMENT = 'CREATION_COMMENT',
  DECLINE_COMMENT = 'DECLINE_COMMENT',
  SYSTEM_COMMENT = 'SYSTEM_COMMENT',
}

export type Order = {
  id: number;
  customerId: number;
  purchaseOrderNum?: string;
  salesOrderNum?: string;
  status: OrderStatus;
  addressId?: number;
  customAddress?: string;
  hasBeenConfirmed: boolean;
  stdOrder?: number;
  customerInput: boolean;
  isStandardProductOrder: boolean;
} & Audit;

export type GetOrdersRequest = {
  customerId?: number;
  purchaseOrderNum?: string;
  comboSearch?: string;
  status?: OrderStatus;
  orderBy?: OrderOrderBy;
  order?: PortalSortOrder;
  showAll?: boolean;
  myOrders?: boolean;
  offset: number;
  limit: number;
  userId?: number;
  count: boolean;
};

export type DetailedOrder = {
  id: number;
  customerId: number;
  customerName: string;
  purchaseOrderNum: string;
  addressId: number;
  fullAddress: string;
  status: OrderStatus;
  salesOrderId: number;
  salesOrderNum: string;
  salesOrderCreatedAt: string;
  paymentType: string;
  paymentNumberOfDays: number;
  customerCreditMaxMst: number;
  customerInput: boolean;
  deliveryTerm: string;
  yearContract: boolean;
  contractUrl: string;
  isStandardProductOrder?: boolean;
  customerDeliveryDays?: CustomerDeliveryDaysType;

} & Audit;

export type PurchaseOrderProduct = {
  id: number;
  purchaseOrderId: number;
  productId: number;
  customerReceiveDate: string;
  deliveryDate: string;
  quantity: number;
  unit: string;
  keepInStock: boolean;
  productName: string;
  productNameEN: string;
  itemId: string;
  externalItemId: string;
  ean: string;
} & Audit;

export type SalesOrderProduct = {
  id: number;
  salesOrderId: number;
  productId: number;
  proposedDate: string;
  quantity: number;
  unit: string;
  price: number;
  currency: string;
  customProductName: string;
  customProductCode: string;
  productName: string;
  productNameEN: string;
  productCode: string;
  externalItemId: string;
  ean: string;
  keepInStock: boolean;
  linePrice: number;
} & Audit;

export type SalesOrderLiveRow = {
  requestedQuantity: number;
  unit: string;
  salesStatus: string;
  requestedDelivery: string;
  quantityDelivered: number;
  deliveryDate: string;
  price: number;
  currency: string;
  invoiceId: string;
  unitPrice: number;
};

export type SalesOrderLiveGroup = {
  rowId: string;
  productCode: string;
  productName: string;
  externalItemId: string;
  ean: string;
  rows: SalesOrderLiveRow[];
};

export type SalesOrderProductLiveGroup = {
  id: number;
  salesOrderId: number;
  productId: number;
  proposedDate: string;
  quantity: number;
  unit: string;
  price: number;
  currency: string;
  customProductName: string;
  customProductCode: string;
  productName: string;
  productNameEN: string;
  productCode: string;
  externalItemId: string;
  ean: string;
  keepInStock: boolean;
  linePrice?: number;
  liveRows: SalesOrderLiveRow[];
}

export type OrderComment = {
  id: number;
  purchaseOrderId: number;
  salesOrderId: number;
  createdByUser: string;
  version: number;
  salesOrderVersion: number;
  content: string;
  type: OrderCommentType;
} & Audit;

export type UpdateOrderRequest = {
  addressId: number;
  purchaseOrderNum: string;
};

export type PlaceOrderRequest = {
  content?: string;
};

export type AddOrderProductRequest = {
  productId: number;
  quantity: number;
  unit: string;
  customerReceiveDate?: string;
  deliveryDate?: string;
  keepInStock?: boolean;
};

export type UpdatePurchaseOrderProductRequest = {
  quantity: number;
  unit: string;
  customerReceiveDate?: string;
  deliveryDate?: string;
  keepInStock?: boolean;
};

export enum OrderType {
  DEFAULT = 'DEFAULT',
  STANDARD = 'STANDARD',
}

export type ContractAgreementRequest = {
  agreedToContract: boolean;
  contractTermsShown: boolean;
}
